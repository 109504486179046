<script setup lang="ts">
const route = useRoute()
const config = useRuntimeConfig()
const { t, locale } = useI18n()
const localePath = useLocalePath()
const dayjs = useDayjs()

const formatDate = (date: string) => {
  if (locale.value === 'hk') {
    return dayjs(date).locale(locale.value).format('YYYY年MM月DD日')
  }
  return dayjs(date).locale(locale.value).format('MMM DD, YYYY')
}

const { app } = useAppConfig()
const { heroIcons } = useHeroIcons()
const { navLinks } = useNavLinks()

// filter links with isLandingVisible
const visibleLinks = computed(
  () =>
    navLinks.value?.flatMap((link) =>
      link.children.filter((child) => child.isLandingVisible)
    ) ?? []
)

// latest 6 articles
const { landingArticles, articleStatus, articleError } = useLandingArticle(6)

const { clinics } = useClinic()

const colorMode = useColorMode()
const isDark = computed(() => (colorMode.value === 'dark' ? 1 : 0))

useJsonLd()

useSeoMeta({
  title: () => t('app.title'),
  description: () => t('app.description'),
  ogTitle: () => t('app.title'),
  ogImage: () => app.ogImg,
  ogUrl: () => config.public.baseUrl + route.path,
  ogDescription: () => t('app.description'),
  twitterTitle: () => t('app.title'),
  twitterImage: () => app.ogImg,
  twitterDescription: () => t('app.description'),
  twitterCard: () => 'summary_large_image',
})
</script>

<template>
  <div>
    <NuxtRouteAnnouncer />

    <!-- Hero Section -->
    <div class="relative h-full">
      <div
        class="bg-gradient-to-b from-primary-100/30 to-netural-100/20 dark:from-orange-500/10 dark:to-netural-800/10 mx-auto px-4 relative flex flex-col size-full items-center justify-center overflow-hidden bg-background pb-60 pt-20 lg:pt-20 lg:pb-[300px]"
      >
        <!-- Snowfall Background -->
        <!-- <InspiraSnowfallBg
          color="#e8e8e8"
          class="absolute inset-0"
          :min-radius="0.2"
          :max-radius="5"
          :speed="0.5"
        /> -->

        <!-- Left third icons - scattered layout -->
        <div
          class="absolute left-0 inset-y-0 w-1/3 overflow-hidden pointer-events-none"
        >
          <div
            v-for="icon in heroIcons.filter((icon) => icon.side === 'left')"
            :key="icon.name"
            class="absolute opacity-[0.1] dark:opacity-[0.1]"
            :style="{
              left: `${icon.position}%`,
              top: `${icon.top}%`,
              transform: `rotate(${icon.rotate}deg) scale(${icon.scale})`,
            }"
          >
            <UIcon
              :name="icon.name"
              class="w-8 h-8 lg:w-16 lg:h-16 text-primary-500"
            />
          </div>
        </div>

        <!-- Right third icons - scattered layout -->
        <div
          class="absolute right-0 inset-y-0 w-1/3 overflow-hidden pointer-events-none"
        >
          <div
            v-for="icon in heroIcons.filter((icon) => icon.side === 'right')"
            :key="icon.name"
            class="absolute opacity-[0.1] dark:opacity-[0.1]"
            :style="{
              right: `${icon.position}%`,
              top: `${icon.top}%`,
              transform: `rotate(${icon.rotate}deg) scale(${icon.scale})`,
            }"
          >
            <UIcon
              :name="icon.name"
              class="w-8 h-8 lg:w-16 lg:h-16 text-primary-500"
            />
          </div>
        </div>

        <!-- Heading  -->
        <div
          class="w-full max-w-screen-sm text-5xl sm:text-6xl lg:text-7xl font-bold max-lg:-mt-12 text-neutral-600 dark:text-neutral-300"
        >
          <h1 :class="[locale === 'en' ? 'text-left' : 'text-center']">
            {{ t('landing.stay')
            }}<InspiraFlipWords
              :words="[t('landing.healthy'), t('landing.safe')]"
              :duration="2500"
              class="text-primary-500 dark:!text-primary-500"
            />

            <div v-if="locale === 'en'" class="text-right">
              {{ t('landing.everywhere') }}
            </div>
          </h1>
        </div>

        <!-- Description -->
        <div
          class="mt-6 max-w-3xl text-lg sm:text-xl text-neutral-500 dark:text-neutral-400 text-center"
        >
          {{ t('landing.description') }}
        </div>

        <!-- Booking Button -->
        <ULink :to="localePath('/appointment')" class="mt-6">
          <InspiraShimmerButton
            class="shadow-lg"
            shimmerSize="2px"
            background="rgba(249,115,22,1)"
          >
            <span
              class="whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg"
            >
              {{ t('cta.booking') }}
            </span>
          </InspiraShimmerButton>
        </ULink>

        <!-- Globe -->
        <InspiraGlobe
          :key="isDark"
          :class="
            locale === 'en'
              ? 'mt-80 sm:mt-80 lg:mt-96'
              : 'mt-60 sm:mt-60 lg:mt-80'
          "
          :dark="isDark"
        />
        <!-- <div
        class="pointer-events-none absolute inset-0 h-full bg-gradient-to-b from-orange-500/30 to-gray-100/20 dark:from-orange-500/10 dark:to-gray-800/10"
      /> -->
      </div>
    </div>

    <!-- Core Services Section -->
    <div class="bg-neutral-50 dark:bg-neutral-900/50">
      <KSection
        icon="i-tabler-sitemap"
        :title="t('coreService.title')"
        :description="t('coreService.description')"
      >
        <!-- <div
          class="relative flex h-[500px] w-full flex-col items-center justify-center overflow-hidden"
        >
   
          <div class="relative z-20 text-center">
            <ULandingSection
              :title="t('coreService.title')"
              :description="t('coreService.description')"
            >
            </ULandingSection>
          </div>

     
          <InspiraOrbit
            v-for="(service, index) in visibleLinks"
            :key="service.id"
            :duration="25"
            :delay="getDelay(index)"
            :radius="getRadius(index)"
            :direction="
              index < 4
                ? ORBIT_DIRECTION.Clockwise
                : ORBIT_DIRECTION.CounterClockwise
            "
            class="border-none bg-transparent items-center justify-center z-10 opacity-70"
          >
            <div
              class="p-2 rounded-full bg-primary-50 dark:bg-neutral-700 flex items-center justify-center"
            >
              <UIcon
                :name="`${service.icon}`"
                class="w-8 h-8 bg-primary-500 dark:bg-primary-600 hover:bg-primary-600 dark:hover:bg-primary-700"
                size="lg"
              />
            </div>
          </InspiraOrbit>
        </div> -->
        <!-- Service Cards (full width) -->
        <template #body>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8"
          >
            <NuxtLink
              v-for="service in visibleLinks"
              :key="service.id"
              :to="service.to"
              class="group ke-card"
            >
              <div class="relative h-48 overflow-hidden">
                <img
                  :src="service.cover"
                  :alt="service.alt"
                  class="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                  loading="lazy"
                />
              </div>
              <div class="p-6 sm:p-6 lg:px-8 lg:py-6">
                <div class="flex flex-row items-start justify-center">
                  <div>
                    <UIcon
                      v-if="service.icon"
                      :name="service.icon"
                      class="w-6 h-6 text-primary-500 mr-2 mt-0.5"
                    />
                  </div>
                  <h3
                    class="mb-2 md:mb-2 text-lg md:text-xl font-semibold group-hover:text-primary-500 dark:group-hover:text-primary-400 transition-colors duration-500"
                  >
                    {{ service.label }}
                  </h3>
                </div>
                <p
                  class="text-sm lg:text-md text-neutral-600 dark:text-neutral-400 text-center"
                >
                  {{ service.description }}
                </p>
              </div>
            </NuxtLink>
          </div>
        </template>
      </KSection>
    </div>

    <!-- Milestones -->
    <div class="bg-primary-50 dark:bg-neutral-800/50">
      <AboutMilestones />
    </div>

    <!-- Articles -->
    <div class="bg-neutral-50 dark:bg-neutral-900/50">
      <KSection
        icon="i-tabler-book"
        :title="t('articles.title')"
        :description="t('articles.description')"
      >
        <template #body>
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8"
          >
            <div
              v-if="articleError"
              class="col-span-full text-center text-red-500"
            >
              {{ t('error.fetchFailed') }}
            </div>

            <!-- Skeleton loader -->
            <template v-if="articleStatus === 'pending'">
              <div
                v-for="index in 6"
                :key="index"
                class="rounded-xl shadow-md overflow-hidden"
              >
                <div class="animate-pulse">
                  <!-- Image placeholder -->
                  <div class="aspect-video bg-gray-300 dark:bg-gray-700"></div>

                  <div class="p-6 space-y-2 flex flex-col flex-grow">
                    <!-- Category and date -->
                    <div class="flex justify-between items-start">
                      <div
                        class="w-24 h-6 bg-gray-300 dark:bg-gray-700 rounded"
                      ></div>
                      <div
                        class="w-32 h-4 bg-gray-300 dark:bg-gray-700 rounded"
                      ></div>
                    </div>

                    <!-- Title -->
                    <div
                      class="h-7 bg-gray-300 dark:bg-gray-700 rounded w-3/4 mt-1"
                    ></div>

                    <!-- Description -->
                    <div class="space-y-2">
                      <div
                        class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-full"
                      ></div>
                      <div
                        class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-5/6"
                      ></div>
                      <div
                        class="h-4 bg-gray-300 dark:bg-gray-700 rounded w-4/6"
                      ></div>
                    </div>

                    <!-- Tags -->
                    <div class="flex flex-wrap gap-2">
                      <div
                        class="w-16 h-6 bg-gray-300 dark:bg-gray-700 rounded"
                      ></div>
                      <div
                        class="w-20 h-6 bg-gray-300 dark:bg-gray-700 rounded"
                      ></div>
                      <div
                        class="w-24 h-6 bg-gray-300 dark:bg-gray-700 rounded"
                      ></div>
                    </div>

                    <div class="flex-grow"></div>

                    <!-- Read more link -->
                    <div
                      class="w-28 h-5 bg-gray-300 dark:bg-gray-700 rounded mt-auto"
                    ></div>
                  </div>
                </div>
              </div>
            </template>

            <!-- Article cards -->
            <template v-else>
              <article
                v-for="post in landingArticles"
                :key="post.id"
                class="flex flex-col ke-card"
              >
                <NuxtLink
                  :to="post.link"
                  class="block relative aspect-video overflow-hidden group"
                >
                  <img
                    :src="post.cover"
                    :alt="post.alt"
                    class="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    loading="lazy"
                  />
                </NuxtLink>

                <div
                  class="p-6 sm:p-6 lg:p-8 space-y-2 flex flex-col flex-grow"
                >
                  <div class="flex justify-between items-center">
                    <NuxtLink
                      :to="
                        localePath(
                          `/articles/category/${post.article_category.slug}`
                        )
                      "
                    >
                      <UBadge
                        color="blue"
                        variant="subtle"
                        :label="post.article_category.name"
                        class="hover:bg-blue-100 dark:hover:bg-blue-900 transition-colors duration-500"
                      />
                    </NuxtLink>
                    <time
                      :datetime="formatDate"
                      :title="formatDate"
                      class="text-xs text-neutral-500 dark:text-neutral-400"
                      aria-label="Published date"
                    >
                      {{ formatDate(post.publishedAt) }}
                    </time>
                  </div>

                  <NuxtLink :to="post.link">
                    <h3
                      class="text-xl font-semibold text-gray-900 dark:text-white hover:text-primary-500 dark:hover:text-primary-400 transition-colors duration-500 mt-1"
                    >
                      {{ post.title }}
                    </h3>
                  </NuxtLink>

                  <p
                    class="text-sm md:text-md text-neutral-500 dark:text-neutral-400 line-clamp-3"
                  >
                    {{ post.description }}
                  </p>

                  <!-- <div class="flex flex-wrap gap-2">
                    <NuxtLink
                      v-for="tag in post.article_tags"
                      :key="tag.slug"
                      :to="localePath(`/articles/tag/${tag.slug}`)"
                    >
                      <UBadge
                        color="emerald"
                        variant="soft"
                        size="sm"
                        :label="tag.name"
                        class="hover:bg-emerald-100 dark:hover:bg-emerald-900 transition-colors duration-500"
                      />
                    </NuxtLink>
                  </div> -->

                  <div class="flex-grow"></div>
                  <NuxtLink
                    :to="post.link"
                    class="group relative inline-flex items-center justify-center overflow-hidden rounded-full bg-neutral-100 dark:bg-neutral-800 px-6 py-2 text-sm font-medium transition-all duration-500 ease-out hover:scale-105 mx-auto"
                  >
                    <span
                      class="relative z-10 text-primary-400 dark:text-neutral-400 transition-colors duration-500 group-hover:text-white"
                    >
                      {{ t('cta.readMore') }}
                    </span>
                    <UIcon
                      name="i-tabler-arrow-right"
                      class="relative z-10 ml-1 w-4 h-4 text-primary-400 dark:text-neutral-400 transition-all duration-500 group-hover:text-white group-hover:translate-x-1"
                    />
                    <div
                      class="absolute inset-0 z-0 h-full w-full translate-x-[-101%] bg-primary-500 transition-all duration-500 group-hover:translate-x-0"
                    ></div>
                  </NuxtLink>
                </div>
              </article>
            </template>
          </div>
        </template>
        <template #footer>
          <div class="flex justify-center">
            <UButton
              :ui="{ rounded: 'rounded-full' }"
              :to="localePath('/articles')"
              :label="t('cta.readAll')"
              color="primary"
              size="xl"
              class="px-6 py-2"
              trailingIcon="i-tabler-arrow-narrow-right"
            />
          </div>
        </template>
      </KSection>
    </div>

    <!-- Clinic  -->
    <div class="bg-white dark:bg-neutral-800/50">
      <KSection
        icon="i-tabler-building-estate"
        :title="t('location.title')"
        :description="t('location.description')"
      >
        <template #body>
          <div class="mt-12 grid gap-4 sm:gap-6 lg:gap-8 md:grid-cols-2">
            <div
              v-for="(clinic, index) in clinics"
              :key="clinic.id"
              class="group rounded-xl ke-shadow overflow-hidden"
            >
              <div class="relative h-48">
                <img
                  :src="clinic.clinicImg"
                  :alt="clinic.label"
                  class="w-full h-full object-cover"
                  loading="lazy"
                />
                <div class="absolute inset-0"></div>
                <img
                  :src="clinic.logoUrl"
                  :alt="clinic.label"
                  class="absolute bottom-4 left-4 w-16 h-16 object-contain bg-white dark:bg-neutral-900 rounded-lg p-2"
                  loading="lazy"
                />
              </div>
              <div class="p-6 sm:p-6 lg:p-8">
                <div class="flex flex-col items-left gap-y-4">
                  <!-- clinic name -->
                  <div>
                    <h3
                      class="text-xl font-bold text-gray-900 dark:text-white mb-2"
                    >
                      {{ clinic.label }}
                    </h3>
                  </div>

                  <!--  business address -->
                  <div>
                    <h4
                      class="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1"
                    >
                      {{ t('location.address') }}:
                    </h4>
                    <p class="text-sm text-gray-600 dark:text-gray-400">
                      {{ clinic.address }}
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400 mb-2">
                      {{ clinic.nearestTransport }}
                    </p>

                    <!-- <UButton
                    :label="t('cta.direction')"
                    color="red"
                    :to="clinic.googleBusinessUrl"
                    icon="i-tabler-map-pin"
                    size="sm"
                    variant="ghost"
                  /> -->
                  </div>

                  <!--  business hours -->
                  <!-- <div>
                  <h4
                    class="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1"
                  >
                    {{ t('location.businessHours') }}:
                  </h4>
                  <p class="text-sm text-gray-600 dark:text-gray-400">
                    {{ clinic.openingHours }}
                  </p>
                </div> -->

                  <!--  business hours -->
                  <div>
                    <!-- <h4
                    class="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-1"
                  >
                    {{ t('location.contactUs') }}:
                  </h4> -->

                    <div class="flex space-x-2">
                      <UButton
                        :label="t('cta.venueInfo')"
                        :color="index === 0 ? 'primary' : 'cyan'"
                        :to="localePath(`/about/#${clinic.slug}`)"
                        icon="i-tabler-building-estate"
                        size="sm"
                        variant="ghost"
                      />
                      <UButton
                        :label="t('cta.direction')"
                        color="red"
                        :to="clinic.googleBusinessUrl"
                        icon="i-tabler-map-pin"
                        size="sm"
                        variant="ghost"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </KSection>
    </div>
  </div>
  <!-- </div> -->
</template>

<style scoped></style>
